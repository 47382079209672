<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import {
  MigrationModule,
  MigrationTaskCollection,
  MigrationTaskData,
} from "@planetadeleste/vue-mc-gw";
import { number } from "mathjs";
import PaginateMixin from "@/mixins/PaginateMixin";

@Component({})
export default class ModuleView extends Mixins(DataTableMixin, PaginateMixin) {
  obModule: MigrationModule | null = null;
  obTaskCollection: MigrationTaskCollection = new MigrationTaskCollection();
  loading = false;
  sMethod: string = "loadTasks";

  get items(): MigrationTaskData[] {
    return this.obTaskCollection.getModelList() as Array<MigrationTaskData>;
  }

  onSetHeaders() {
    const arHeaders: DataTableHeader[] = [
      { text: "status", value: "status" },
      { text: "date", value: "created_at" },
      { text: "original.record.id", value: "value" },
      { text: "error.message", value: "error" },
      { text: "jobid", value: "job_id" },
    ];
    this.setDTHeaders(arHeaders);
  }

  async loadTasks() {
    if (!this.obModule) {
      return;
    }

    this.loading = true;
    const obCollection = new MigrationTaskCollection();
    obCollection.filterBy({ module: this.obModule.id });
    obCollection.page(this.currentPage);
    const obResponse = await obCollection.fetch();

    if (obResponse) {
      const obData = obResponse.getData();
      this.mapPagination(obData);
      this.obTaskCollection.clear();
      this.obTaskCollection.add(obData.data);
    }

    this.loading = false;
  }

  async mounted() {
    this.onSetHeaders();

    if (!this.$route.params.id) {
      return;
    }

    this.loading = true;
    this.obModule = new MigrationModule({ id: number(this.$route.params.id) });
    await this.obModule.fetch();
    await this.loadTasks();
  }
}
</script>

<template>
  <data-table
    :headers="headers"
    :items="items"
    :last-page="serverLastPage"
    :loading="loading"
    :options="pagination"
    :total="serverItemsLength"
    btn-action-item-key-id="id"
    btn-action-path="migrations"
    hide-btn-action-update
    hide-btn-action-view
    hide-btn-action-delete
    is-route-name
    @update:options="onPagination"
    @update:page="onPage"
  >
    <template #top>
      <v-toolbar v-if="obModule" flat>
        <v-toolbar-title>{{ obModule.name }}</v-toolbar-title>
      </v-toolbar>
    </template>

    <template #[`item.status`]="{ item }">
      {{ $t(`migration.status.${item.status}`) }}
    </template>
  </data-table>
</template>
